import { random } from 'lodash-es'
import Component from '../classes/component.js'
import tweens from '../objects/tweens.js'

export default class RandomComponent extends Component {
  init () {
    const intListener = new IntersectionObserver(entries => {
      entries
        .filter(entry => entry.isIntersecting && entry.target.classList.contains('d-ui-hidden'))
        .forEach(entry => {
          const isTitle = entry.target.parentNode.classList.contains('d-random-title')
          const delay = isTitle ? 0 : random(0.75, true)
          entry.target.classList.remove('d-ui-hidden')
          tweens.tweenReveal(entry.target, {}, { delay })
        })
    })
    if (this.refs.images && this.refs.images.length) {
      this.refs.images.forEach(el => intListener.observe(el))
    }
    if (this.refs.title && this.refs.title.length) {
      this.refs.title.forEach(el => intListener.observe(el))
    }
    if (this.refs.text && this.refs.text.length) {
      this.refs.text.forEach(el => intListener.observe(el))
    }
    if (this.refs.link) {
      intListener.observe(this.refs.link)
    }
  }
}
