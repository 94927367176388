import Component from '../classes/component.js'
import gsap from 'gsap'

export default class ProductsSliderComponent extends Component {
  init () {
    if (this.refs.bars && this.refs.bars.length) {
      // double items
      this.refs.bars.forEach(ul => {
        ul.querySelectorAll('li').forEach(li => {
          ul.appendChild(li.cloneNode(true))
        })
      })
      this.tweenSliders()
      this.refs.links = Array.from(this.el.querySelectorAll('a'))
    }

    if (this.refs.links) {
      this.refs.links.forEach(el => {
        el.addEventListener('mouseenter', () => document.dispatchEvent(new CustomEvent('cursor.display')))
        el.addEventListener('mouseleave', () => document.dispatchEvent(new CustomEvent('cursor.hide')))
      })
    }
    
    this.el.addEventListener("mouseenter", () => {
      if (this.tweenBar1) {
        this.tweenBar1.pause()
      }
      if (this.tweenBar2) {
        this.tweenBar2.pause()
      }
    })
    
    this.el.addEventListener("mouseleave", () => {
      if (this.tweenBar1) {
        this.tweenBar1.play()
      }
      if (this.tweenBar2) {
        this.tweenBar2.play()
      }
    })
  }

  tweenSliders() {
    if (this.refs.bars && this.refs.bars[0]) {
      this.tweenBar1 = gsap.fromTo(
        this.refs.bars[0],
        {
          xPercent: 0
        },
        {
          xPercent: -50,
          repeat: Infinity,
          duration: 500 / this.refs.bars[0].querySelectorAll('li').length,
          ease: 'none'
        }
      )
    }
    if (this.refs.bars && this.refs.bars[1]) {
      this.tweenBar2 = gsap.fromTo(
        this.refs.bars[1],
        {
          xPercent: -50
        },
        {
          xPercent: 0,
          repeat: Infinity,
          duration: 500 / this.refs.bars[1].querySelectorAll('li').length,
          ease: 'none'
        }
      )
    }
  }
}
