import app from './objects/app.js'
import vh from './objects/vh.js'
import mediaQuery from './objects/mediaquery'
import CollectionsHero from './components/collectionshero.js'
import CollectionsList from './components/collectionslist.js'
import Cursor from './components/cursor.js'
import InfoBoxes from './components/infoboxes.js'
import MobileMenu from './components/mobilemenu'
import ProductSliderComponent from './components/productslider.js'
import ProductsSliderComponent from './components/productsslider.js'
import ProductsListComponent from './components/productslist.js'
import RandomComponent from './components/random.js'
import SliderComponent from './components/slider.js'
import Slider2Component from './components/slider2.js'
import TopComponent from './components/top.js'

app
  .addComponent('collectionshero', CollectionsHero)
  .addComponent('collectionslist', CollectionsList)
  .addComponent('cursor', Cursor)
  .addComponent('infoboxes', InfoBoxes)
  .addComponent('mobilemenu', MobileMenu)
  .addComponent('productslider', ProductSliderComponent)
  .addComponent('productsslider', ProductsSliderComponent)
  .addComponent('productslist', ProductsListComponent)
  .addComponent('random', RandomComponent)
  .addComponent('slider', SliderComponent)
  .addComponent('slider2', Slider2Component)
  .addComponent('top', TopComponent)
  .addPlugin(vh)
  .init()

mediaQuery.add('small', '(max-width: 800px)')
mediaQuery.add('medium', '(min-width: 801px) and (max-width: 1024px)')
mediaQuery.add('large', '(min-width: 1025px) and (max-width: 1600px)')
