import Component from '../classes/component.js'
import gsap from 'gsap'
import tweens from '../objects/tweens.js'

export default class CursorComponent extends Component {
  constructor (el) {
    super(el)
    this.refs.border = el.nextElementSibling
    this.data = {
      position: {
        top: 0,
        left: 0
      },
      display: false,
      style: 'default'
    }
  }

  init () {
    this.watch('display', value => {
      if (value) {
        this.el.classList.remove('d-ui-hidden')
        this.refs.border.classList.remove('d-ui-hidden')
      } else {
        this.el.classList.add('d-ui-hidden')
        this.refs.border.classList.add('d-ui-hidden')
      }
    })

    this.watch('position', value => {
      this.el.style.top = `${value.top}px`
      this.el.style.left = `${value.left}px`

      gsap.to(
        this.refs.border,
        {
          top: value.top,
          left: value.left,
          duration: 0.75,
          ease: 'power1.out'
        }
      );
    })

    this.watch('style', value => {
      if (value === 'default') {
        this.refs.plus.style.display = null
        this.refs.next.style.display = 'none'
        this.refs.prev.style.display = 'none'
      }
      if (value === 'next') {
        this.refs.plus.style.display = 'none'
        this.refs.next.style.display = null
        this.refs.prev.style.display = 'none'
      }
      if (value === 'prev') {
        this.refs.plus.style.display = 'none'
        this.refs.next.style.display = 'none'
        this.refs.prev.style.display = null
      }
    })

    this.setListener()
  }

  setListener() {
    document.addEventListener("cursor.display", () => {
      this.data.display = true
      this.data.style = 'default'
    })
    
    document.addEventListener("cursor.displayNext", () => {
      this.data.display = true
      this.data.style = 'next'
    })
    
    document.addEventListener("cursor.displayPrev", () => {
      this.data.display = true
      this.data.style = 'prev'
    })

    document.addEventListener("cursor.hide", () => {
      this.data.display = false
    })

    window.addEventListener('mousemove', e => {
      this.data.position = {
        top: e.pageY,
        left: e.pageX
      }
    })
  }
}
