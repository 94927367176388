import Component from '../classes/component.js'
import utils from '../objects/utils.js'
import mediaquery from '../objects/mediaquery.js'
import gsap from 'gsap'

export default class TopComponent extends Component {
  constructor(el) {
    super(el)
    this.data = {
      topIntersected: false,
      scrollDirection: 'up'
    }
  }

  init() {
    // intersection line
    this.refs.intersection = document.querySelector('.d-top-intersection')
    const intObserver = new IntersectionObserver(entries => {
      this.data.topIntersected = entries[0].isIntersecting
    })
    intObserver.observe(this.refs.intersection)

    this.watch('topIntersected', value => {
      this.el.classList[value ? 'remove' : 'add']('d-top--fixed')
    })
    
    this.watch('scrollDirection', value => {
      this.el.classList[value === 'up' ? 'remove' : 'add']('d-top--scrolldown')
    })

    let scrollTop = window.pageYOffset
    window.addEventListener('scroll',
      utils.debounce(() => {
        const currentScroll = window.pageYOffset
        if (currentScroll <= 10) {
          this.data.scrollDirection = 'up'
        }
        else if (currentScroll > scrollTop && this.data.scrollDirection === 'up') {
          this.data.scrollDirection = 'down'
        }
        else if (currentScroll < scrollTop && this.data.scrollDirection === 'down') {
          this.data.scrollDirection = 'up'
        }
        scrollTop = currentScroll
      },
      10,
      true
      )
    )

    document.addEventListener('mobilemenu.in', () => this.tweenMobileIconClose())
    document.addEventListener('mobilemenu.out', () => this.tweenMobileIconNav())
  }

  toggleLink () {
    if (mediaquery.test('small')) {
      if (!this.refs.link.classList.contains('d-ui-hidden')) {
        this.refs.link.classList.remove('d-ui-hidden')
      }
      document.dispatchEvent(new CustomEvent('mobilemenu.toggle'))
    } else {
      this.refs.link.classList.toggle('d-ui-hidden')
    }
  }

  tweenMobileIconClose () {
    const tl = gsap.timeline()
    tl.to(
      this.refs.menuIconLine1,
      {
        y: 7,
        ease: 'power2.inOut',
        duration: 0.75
      },
      0
    )
    tl.to(
      this.refs.menuIconLine2,
      {
        opacity: 0,
        ease: 'power2.inOut',
        duration: 0.75
      },
      0
    )
    tl.to(
      this.refs.menuIconLine3,
      {
        y: -7,
        ease: 'power2.inOut',
        duration: 0.75
      },
      0
    )
    tl.to(
      this.refs.menuIconLine1,
      {
        rotate: 45,
        ease: 'power2.inOut',
        duration: 0.75,
        onStart: () => {
          this.refs.menuIconLine1.style.transformOrigin = `50% 50%`
        }
      },
      0.75
    )
    tl.to(
      this.refs.menuIconLine3,
      {
        rotate: -45,
        ease: 'power2.inOut',
        duration: 0.75,
        onStart: () => {
          this.refs.menuIconLine3.style.transformOrigin = `50% 50%`
        }
      },
      0.75
    )
    return tl
  }

  tweenMobileIconNav () {
    const tl = gsap.timeline({
      onComplete: () => {
        this.refs.menuIconLine1.removeAttribute('style')  
        this.refs.menuIconLine1.removeAttribute('style')  
        this.refs.menuIconLine1.removeAttribute('style')  
      }
    })
    tl.fromTo(
      this.refs.menuIconLine1,
      {
        rotate: -45
      },
      {
        rotate: 0,
        ease: 'power2.inOut',
        duration: 0.75
      },
      0
    )
    tl.fromTo(
      this.refs.menuIconLine3,
      {
        rotate: 45
      },
      {
        rotate: 0,
        ease: 'power2.inOut',
        duration: 0.75
      },
      0
    )
    tl.fromTo(
      this.refs.menuIconLine1,
      {
        y: 7
      },
      {
        y: 0,
        ease: 'power2.inOut',
        duration: 0.75
      },
      0.75
    )
    tl.fromTo(
      this.refs.menuIconLine3,
      {
        y: -7
      },
      {
        y: 0,
        ease: 'power2.inOut',
        duration: 0.75
      },
      0.75
    )
    tl.fromTo(
      this.refs.menuIconLine2,
      {
        opacity: 0
      },
      {
        opacity: 1,
        ease: 'power2.inOut',
        duration: 0.75
      },
      0.75
    )
    return tl
  }
}
