import Component from '../classes/component.js'
import Splide from '@splidejs/splide'
import GLightbox from 'glightbox';

export default class ProductSliderComponent extends Component {
  init() {
    const stopSlider = this.refs.slides?.length && this.refs.slides.length <= 1

    this.slider = new Splide(
      this.refs.slider,
      {
        type: stopSlider ? 'slide' : 'loop',
        arrows: false,
        pagination: false,
        autoplay: stopSlider ? false : true,
        interval: 5000,
        autoWidth: true
      }
    ).mount()

    this.refs.slider.addEventListener('click', (e) => {
      let target = e.target
      while (target && !target.classList.contains('d-productslider-slide')) {
        target = target.parentNode
      }
      if (target && target.classList.contains('d-productslider-slide--video')) {
        const index = parseInt(target.dataset.index)
        if (window?.__sliderItems?.[index]?.videoEmbedCode) {
          
          const lightbox = GLightbox({
            elements: [
              {
                href: window.__sliderItems[index].videoEmbedCode,
                type: 'video',
                source: 'youtube',
                width: window.innerWidth * 0.7
              }
            ],
            autoplayVideos: true
          })
          lightbox.open()
        }
      }
    })

    if (stopSlider) {
      this.el.classList.add('d-productslider--disabled')
    }
  }
}
