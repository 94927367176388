import gsap from 'gsap'

export default {
  tweenMask(el, type = 'left-to-right', params = {}) {
    const obj = { value: 100 }

    if (type === 'left-to-right') {
      el.style.webkitClipPath = 'inset(0% 100% 0% 0%)'
      el.style.clipPath = 'inset(0% 100% 0% 0%)'
    }
    if (type === 'bottom-to-top') {
      el.style.webkitClipPath = 'inset(100% 0% 0% 0%)'
      el.style.clipPath = 'inset(100% 0% 0% 0%)'
    }
    if (type === 'top-to-bottom') {
      el.style.webkitClipPath = 'inset(0% 0% 100% 0%)'
      el.style.clipPath = 'inset(0% 0% 100% 0%)'
    }

    const tween = gsap.to(
      obj,
      Object.assign(
        {
          value: 0,
          duration: 1,
          ease: 'power2.inOut',
          onUpdate: () => {
            if (type === 'left-to-right') {
              el.style.webkitClipPath = `inset(0% ${obj.value}% 0% 0%)`
              el.style.clipPath = `inset(0% ${obj.value}% 0% 0%)`
            }
            if (type === 'bottom-to-top') {
              el.style.webkitClipPath = `inset(${obj.value}% 0% 0% 0%)`
              el.style.clipPath = `inset(${obj.value}% 0% 0% 0%)`
            }
            if (type === 'top-to-bottom') {
              el.style.webkitClipPath = `inset(0% 0% ${obj.value}% 0%)`
              el.style.clipPath = `inset(0% 0% ${obj.value}% 0%)`
            }
          },
          onComplete: () => {
            el.style.webkitClipPath = null
            el.style.clipPath = null
          }
        },
        params
      )
    )
    return tween
  },

  tweenReveal (el, from = {}, to = {}) {
    return gsap.fromTo(
      el,
      Object.assign({
        opacity: 0,
        y: '3rem'
      }, from),
      Object.assign({
        opacity: 1,
        y: 0,
        duration: 2,
        ease: 'power1.inOut'
      }, to)
    )
  }
}
