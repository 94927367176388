import gsap from 'gsap/gsap-core'
import Component from '../classes/component.js'
// import tweens from '../objects/tweens.js'
// import _ from 'lodash-es'

export default class MobileMenuComponent extends Component {
  constructor (el) {
    super(el)
    this.data = {
      interactions: true
    }
  }

  init () {
    document.addEventListener('mobilemenu.toggle', () => this.toggle())
  }

  toggle () {
    if (!this.data.interactions) {
      return
    }
    this.data.interactions = false
    if (this.el.classList.contains('d-ui-hidden')) {
      document.body.classList.add('d-ui-unscrollable')
      this.tweenIn().then(() => {
        this.data.interactions = true
      })
      document.dispatchEvent(new CustomEvent('mobilemenu.in'))
    } else {
      document.body.classList.remove('d-ui-unscrollable')
      this.tweenOut().then(() => {
        this.data.interactions = true
      })
      document.dispatchEvent(new CustomEvent('mobilemenu.out'))
    }
  }

  tweenIn () {
    const clipObj = { value: 100 }

    this.el.classList.remove('d-ui-hidden')

    return gsap.to(
      clipObj,
      {
        value: 0,
        duration: 0.75,
        ease: 'power2.inOut',
        onUpdate: () => {
          this.el.style.webkitClipPath = `inset(0% 0% ${clipObj.value}% 0%)`
          this.el.style.clipPath = `inset(0% 0% ${clipObj.value}% 0%)`
        },
        onComplete: () => {
          this.el.style.webkitClipPath = null
          this.el.style.clipPath = null
        }
      }
    )
  }

  tweenOut () {
    const clipObj = { value: 0 }

    return gsap.to(
      clipObj,
      {
        value: 100,
        duration: 0.5,
        ease: 'power2.out',
        onUpdate: () => {
          this.el.style.webkitClipPath = `inset(0% 0% ${clipObj.value}% 0%)`
          this.el.style.clipPath = `inset(0% 0% ${clipObj.value}% 0%)`
        },
        onComplete: () => {
          this.el.style.webkitClipPath = null
          this.el.style.clipPath = null
          this.el.classList.add('d-ui-hidden')
        }
      }
    )
  }
}
