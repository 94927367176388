import hyperactiv from 'hyperactiv'
const { observe, computed } = hyperactiv

export default class Component {
  constructor (el) {
    this.id = null
    this.data = {}
    this.refs = {}
    this.el = el
    if (el.getAttribute('data-id')) {
      this.id = el.getAttribute('data-id')
    }

    // refs setup
    el.querySelectorAll('[data-ref]').forEach(el => {
      let key = el.getAttribute('data-ref')
      const keyMatch = key.match(/^\[(.+)\]$/)
      if (keyMatch) {
        key = keyMatch[1]
        if (!this.refs[key]) {
          this.refs[key] = [el]
        }
        else {
          this.refs[key].push(el)
        }
      } else {
        this.refs[key] = el
      }
      el.removeAttribute('data-ref')
    })

    // bind setup
    el.querySelectorAll('[data-on]').forEach(el => {
      const bind = el.getAttribute('data-on').split(':')
      el.addEventListener(bind[0], () => this[bind[1]]())
      el.removeAttribute('data-on')
    })
  }

  watch (ref, fn) {
    computed(() => {
      this.data[ref]
      setTimeout(() => fn(this.data[ref]))
    })
  }

  init () {}

  setupObserve() {
    this.data = observe(this.data)
  }
}