import Component from '../classes/component.js'
import Splide from '@splidejs/splide'
import mediaQuery from '../objects/mediaquery'

export default class BlockImagesComponent extends Component {
  constructor (el) {
    super(el)
  }

  init() {
    this.slider = new Splide(
      this.el,
      {
        type: 'loop',
        perPage: 1,
        arrows: false,
        pagination: false,
        focus: 'center',
        autoplay: true,
        interval: 5000
      }
    ).mount()

    this.slider.on('moved', () => {
      this.setBackground()
      this.setTitle()
    })

    if (this.refs.links) {
      this.refs.links.forEach(el => {
        el.addEventListener('mouseenter', () => document.dispatchEvent(new CustomEvent('cursor.display')))
        el.addEventListener('mouseleave', () => document.dispatchEvent(new CustomEvent('cursor.hide')))
      })
    }

    this.setBackground()
    this.setTitle()
  }

  next() {
    if (this.slider) {
      this.slider.go('>')
    }
  }

  prev() {
    if (this.slider) {
      this.slider.go('<')
    }
  }

  setBackground() {
    if (window.__collectionsHero && window.__collectionsHero[this.slider.index]) {
      this.refs.list.style.backgroundColor = window.__collectionsHero[this.slider.index]
    } else {
      this.refs.list.style.backgroundColor = null
    }
  }

  setTitle() {
    if (this.refs.collectionsTitles && this.refs.collectionsTitles.length) {
      this.refs.collectionsTitles.forEach(el => el.classList.add('d-ui-hidden'))
      this.refs.collectionsTitles[this.slider.index].classList.remove('d-ui-hidden')
    }
  }
}
