import Component from '../classes/component.js'
import tweens from '../objects/tweens.js'
import _ from 'lodash-es'

export default class CollectionsListComponent extends Component {
  init () {
    const intListener = new IntersectionObserver(entries => {
      entries
        .filter(entry => entry.isIntersecting && entry.target.classList.contains('d-ui-hidden'))
        .forEach(entry => {
          entry.target.classList.remove('d-ui-hidden')
          tweens.tweenMask(entry.target, 'top-to-bottom', { delay: _.random(0.25, true) })
          const title = entry.target.nextElementSibling
          if (title) {
            title.classList.remove('d-ui-hidden')
          }
        })
    })
    if (this.refs.images && this.refs.images.length) {
      this.refs.images.forEach(el => intListener.observe(el))
    }

    if (this.refs.items) {
      this.refs.items.forEach(el => {
        if (el.classList.contains('d-collectionslist-item--preview')) {
          return
        }
        el.addEventListener('mouseenter', () => document.dispatchEvent(new CustomEvent('cursor.display')))
        el.addEventListener('mouseleave', () => document.dispatchEvent(new CustomEvent('cursor.hide')))
      })
    }
  }
}
