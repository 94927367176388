import Component from '../classes/component.js'

export default class ProductsListComponent extends Component {
  init() {
    if (this.refs.items) {
      this.refs.items.forEach(el => {
        el.addEventListener('mouseenter', () => document.dispatchEvent(new CustomEvent('cursor.display')))
        el.addEventListener('mouseleave', () => document.dispatchEvent(new CustomEvent('cursor.hide')))
      })
    }
  }
}
