import _ from 'lodash-es'

const components = {}
const plugins = []

export default {
  async init() {
    return Promise.resolve()
      .then(() => this.documentReady())
      .then(() => this.initPlugins())
      .then(() => this.parseComponents())
  },

  documentReady() {
    return new Promise(resolve => {
      if (['interactive', 'complete'].includes(document.readyState)) {
        resolve()
      } else {
        document.addEventListener('readystatechange', function onReadyStateChange () {
          if (['interactive', 'complete'].includes(document.readyState)) {
            document.removeEventListener('readystatechange', onReadyStateChange)
            resolve()
          }
        })
      }
    })
  },

  addComponent(key, obj) {
    components[key] = obj
    return this
  },

  addPlugin(obj) {
    plugins.push(obj)
    return this
  },

  initPlugins() {
    plugins.forEach(plugin => plugin.init())
  },

  parseComponents(container) {
    let containerEl = document
    if (_.isString(container)) {
      containerEl = document.querySelector(container)
    }
    if (_.isElement(container)) {
      containerEl = container
    }
    const componentsElements = Array.from(containerEl.querySelectorAll('[data-component]'))
    componentsElements.forEach(el => {
      const componentName = el.getAttribute('data-component')
      if (components[componentName]) {
        const componentInstance = new components[componentName](el)
        componentInstance.setupObserve()
        componentInstance.init()
      }
    })
  }
}