import Component from '../classes/component.js'
import mediaQuery from '../objects/mediaquery.js'
import Splide from '@splidejs/splide'

export default class SliderComponent extends Component {
  constructor (el) {
    super(el)
    this.data = {
      currentHover: null
    }
  }

  init() {
    const isSmallDisplay = mediaQuery.test('small')
    this.slider = new Splide(
      this.refs.slider,
      {
        type: 'loop',
        perPage: isSmallDisplay ? 1.2 : 2,
        arrows: false,
        pagination: false,
        focus: 'center',
        padding: {
          top: 0,
          bottom: 0,
          left: isSmallDisplay ? 0 : '2.005208rem',
          right: isSmallDisplay ? 0 : '2.005208rem'
        }
      }
    ).mount()
    
    Array
      .from(this.refs.slider.querySelectorAll('a'))
      .forEach(el => {
        el.addEventListener('mouseenter', () => {
          const isActive = el.parentNode.classList.contains('is-active')
          if (isActive) {
            this.data.currentHover = 'active'
            document.dispatchEvent(new CustomEvent('cursor.display'))
          } else {
            const currentLi = this.refs.slider.querySelector('.is-active')
            if (currentLi) {
              const nextLi = currentLi.nextElementSibling
              const prevLi = currentLi.previousElementSibling
              if (nextLi === el.parentNode) {
                this.data.currentHover = 'next'
                document.dispatchEvent(new CustomEvent('cursor.displayNext'))
              }
              else if (prevLi === el.parentNode) {
                this.data.currentHover = 'prev'
                document.dispatchEvent(new CustomEvent('cursor.displayPrev'))
              }
            }
          }
        })
        
        el.addEventListener('mouseleave', () => {
          this.data.currentHover = null
          document.dispatchEvent(new CustomEvent('cursor.hide'))
        })

        el.addEventListener('click', e => {
          if (this.data.currentHover === 'active') {
            return
          } else {
            e.preventDefault()
            this.refs.slider.style.pointerEvents = 'none'

            if (this.data.currentHover === 'prev') {
              this.slider.go('+')
            }
            if (this.data.currentHover === 'next') {
              this.slider.go('-')
            }
            
            setTimeout(() => {
              this.refs.slider.style.pointerEvents = null
            }, 500)
          }
        })
      })

  }
}
