import _ from 'lodash-es'

export default {
  init() {
    // vh css variable
    function setVh() {
      const vh = window.innerHeight * 0.01;
      window.document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    setVh()
    window.addEventListener('resize', _.debounce(() => setVh(), 200))
  }
}