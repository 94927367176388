import Component from '../classes/component.js'
import Splide from '@splidejs/splide'

export default class Slider2Component extends Component {
  init() {
    this.slider = new Splide(
      this.refs.slider,
      {
        type: 'loop',
        perPage: 1,
        arrows: false,
        pagination: false,
        focus: 'center',
        autoplay: true,
        interval: 5000
      }
    ).mount()
  }

  next() {
    if (this.slider) {
      this.slider.go('>')
    }
  }

  prev() {
    if (this.slider) {
      this.slider.go('<')
    }
  }
}